import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { Subject, takeUntil } from "rxjs";

import { Pilot, Role, RoleDescription } from "../../users/models/users.entity";

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: [ './welcome.component.scss' ]
})
export class WelcomeComponent implements OnInit, OnDestroy {

  destroy$ = new Subject<void>();

  role: Role.Talker | Role.Sherpa | Pilot = Role.Talker;

  description = 'Talker';

  videoLinks: Record<Role.Talker | Role.Sherpa | Pilot, string> = {
    [Role.Sherpa]: 'lyATNJZLbjQ',
    [Role.Talker]: 'hjIlwtzB1UA',
    pilot: 'HZ_72tgof3U'
  };

  playerVars = {
    controls: 1,
    showinfo: 0,
    rel: 0,
    modestbranding: 1,
    playsinline: 1,
  };


  constructor(private readonly route: ActivatedRoute,
              private readonly sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        const { role } = params;
        this.role = role;

        this.description = RoleDescription[this.role];
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  getVideoLink() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${ this.videoLinks[this.role] }`);
  }

  protected readonly Role = Role;
}
