import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgChartsModule } from "ng2-charts";
import { NgxPermissionsModule } from "ngx-permissions";

import { SharedModule } from "../shared/shared.module";


import {
  DynamicTypeDistributionComponent
} from "./charts/dynamic-type-distribution/dynamic-type-distribution.component";
import { GenderDistributionComponent } from './charts/gender-distribution/gender-distribution.component';
import { HorizontalBarChartComponent } from "./charts/horizontal-bar-chart/horizontal-bar-chart.component";
import { ImpactChartComponent } from "./charts/impact-chart/impact-chart.component";
import { SatisfactionDistributionComponent } from "./charts/satisfaction-distribution/satisfaction-distribution.component";
import { TalkersHistoricalComponent } from './charts/talkers-historical/talkers-historical.component';
import { TalkersInvolvementComponent } from "./charts/talkers-involvement/talkers-involvement.component";
import { ThematicDistributionComponent } from "./charts/thematic-distribution/thematic-distribution.component";
import { ThematicProgressionDistributionComponent } from "./charts/thematic-progression-distribution/thematic-progression-distribution.component";
import { TrainFillingComponent } from './charts/train-filling/train-filling.component';
import { TrainsPerTalkerComponent } from './charts/trains-per-talker/trains-per-talker.component';
import { TrainsProgressionComponent } from "./charts/trains-progression/trains-progression.component";
import { TypeformScoreBarChartComponent } from "./charts/typeform-score-bar-chart/typeform-score-bar-chart.component";
import { TypeformScoreDistributionComponent } from "./charts/typeform-score-distribution/typeform-score-distribution.component";
import { UnitsProgressionComponent } from './charts/units-progression/units-progression.component';
import { UsersDistributionComponent } from "./charts/users-distribution/users-distribution.component";
import { VerticalBarChartComponent } from "./charts/vertical-bar-chart/vertical-bar-chart.component";
import { DashboardComponent } from './dashboard.component';
import { DashboardRepository } from "./dashboard.repository";
import { FeedbackStatisticsComponent } from "./feedback-statistics/feedback-statistics.component";
import { StatisticsMtmResolver } from "./resolvers/statistics-mtm.resolver";
import { TalkersEmotionalStateComponent } from "./talkers-emotional-state/talkers-emotional-state.component";

@NgModule({
  declarations: [
    DynamicTypeDistributionComponent,
    TalkersInvolvementComponent,
    ThematicDistributionComponent,
    TrainsProgressionComponent,
    DashboardComponent,
    ThematicDistributionComponent,
    UnitsProgressionComponent,
    TrainFillingComponent,
    GenderDistributionComponent,
    TalkersHistoricalComponent,
    TrainsPerTalkerComponent,
    TypeformScoreBarChartComponent,
    FeedbackStatisticsComponent,
    UsersDistributionComponent,
    SatisfactionDistributionComponent,
    TypeformScoreDistributionComponent,
    VerticalBarChartComponent,
    ThematicProgressionDistributionComponent,
    ImpactChartComponent,
    HorizontalBarChartComponent,
    TalkersEmotionalStateComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgxPermissionsModule.forChild(),
    NgChartsModule,
  ],
  exports: [
    DynamicTypeDistributionComponent,
    TalkersInvolvementComponent,
    ThematicDistributionComponent,
    TrainsProgressionComponent,
    DashboardComponent,
    ThematicDistributionComponent,
    UnitsProgressionComponent,
    TrainFillingComponent,
    GenderDistributionComponent,
    TalkersHistoricalComponent,
    TrainsPerTalkerComponent,
    TypeformScoreBarChartComponent,
    FeedbackStatisticsComponent,
    UsersDistributionComponent,
    SatisfactionDistributionComponent,
    TypeformScoreDistributionComponent,
    VerticalBarChartComponent,
    ThematicProgressionDistributionComponent,
    ImpactChartComponent,
    HorizontalBarChartComponent,
    TalkersEmotionalStateComponent
  ],
  providers: [ DashboardRepository, StatisticsMtmResolver ]
})
export class DashboardModule {
}
