<div class="loading-container" *ngIf="isLoading">
    <div class="spinner"></div>
    <p class="loading-text">Chargement en cours...</p>
</div>
<div *ngIf="hasError">
    <div class="margin-top-xl margin-left">
        <app-button (click)="goBack()">
            <div class="row center-children-vertically">
                <app-icon type="arrow-left"></app-icon>
                <div>Retour</div>
            </div>
        </app-button>
    </div>
    <div class="margin-top-xl">
        <app-notification [isPrimaryCta]="true" buttonMessage="Réjoindre la séance"
            (onButtonClick)="rejoinMeeting()" color="#b65a44" 
            title="Difficulté">
            <div>
                <p>Il semble que vous rencontriez des difficultés à rejoindre la réunion.</p>
                <p>Cliquez sur le bouton pour rejoindre manuellement</p>
            </div>
            <div>
                Si le problème persiste, vérifiez votre connexion Internet ou essayez de rafraîchir la page.
            </div>
        </app-notification>
    </div>
</div>