import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import {
  BehaviorSubject,
  debounceTime,
  merge,
  Observable,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from "rxjs";
import { map } from "rxjs/operators";

import { IPaginationRepository } from "../../../repositories/pagination.interface";
import { capitalize } from "../../../tool-functions/capitalize";
import { EntityFormGroup } from "../../../types/entity-form-group";
import { SnackbarService } from "../../snackbar/snackbar.service";

@Component({
  selector: "app-card-pagination",
  templateUrl: "./card-pagination.component.html",
  styleUrls: ["./card-pagination.component.scss"],
})
export class CardPaginationComponent<
  Entity extends { id: string },
  EntityFilterForm extends {
    [key: string]: string | boolean;
  },
  EntityPropertiesFilter extends EntityFilterForm = EntityFilterForm
> implements OnInit, OnDestroy {
  @Input() mainRepository!: IPaginationRepository<Entity, EntityFilterForm>;

  @Input() name!: string;

  @Input("unique-key") uniqueKey: keyof Entity = "id";

  @Input() newItems$: Subject<Entity[]> = new Subject<Entity[]>();

  @Input() isFemaleName = false;

  @Input() isWrittenInPlural = false;

  @Input() filterForm?: EntityFormGroup<EntityFilterForm>;

  @Input() propertiesFilters$?: BehaviorSubject<
    Partial<EntityPropertiesFilter>
  >;

  @Input() sortBy$?: BehaviorSubject<{ sort: string; order: "ASC" | "DESC" }>;


  @Input() otherFilterOptionCount = 0;

  @Input() withBreadcrumb = false;

  @Input() hasFilters? : boolean;

  @ContentChild("card", { static: false }) cardTemplateRef!: TemplateRef<any>;

  @ContentChild("moreOptionsContent", { static: false })
  optionsContent!: TemplateRef<any>;

  @Output() add = new EventEmitter<void>();

  @Output() download = new EventEmitter<void>();

  @Output() clear = new EventEmitter<void>();

  items$ = new Subject<Entity[]>();

  loading$ = new Subject<void>();

  scroll$ = new Subject<void>();

  nextPageLoading = false;

  totalCount: number = 0;

  withChildrenCount: number = 0;

  firstLoaded = false;

  searchForm: FormControl<string | null> = new FormControl<string>("");

  pageSize$: BehaviorSubject<number> = new BehaviorSubject<number>(25);

  page$: BehaviorSubject<number> = new BehaviorSubject<number>(1);

  protected destroy$ = new Subject<void>();

  constructor(private readonly snackBarService: SnackbarService) {}

  ngOnInit(): void {
    this.page$
      .pipe(
        tap(() => {
          this.loading$.next();
        }),
        debounceTime(300),
        switchMap(() =>
          this.mainRepository.paginate({
            page: this.page$.getValue(),
            pageSize: this.pageSize$.getValue(),
            search: this.searchForm.value ?? "",
            properties: this.propertiesFilters$?.getValue(),
            sortBy: this.sortBy$?.getValue(),
          })
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(({ items, totalCount, withChildrenCount }: any) => {
        this.firstLoaded = true;
        this.items$.next(items);
        this.totalCount = totalCount;
        const hasSearchQuery = !!this.searchForm?.value?.trim();
        const hasFilters = Object.keys(this.propertiesFilters$?.getValue() || {}).length > 0;
        this.withChildrenCount = (hasSearchQuery || hasFilters) ? totalCount : (withChildrenCount || totalCount);
        this.nextPageLoading = false;
      });

    merge(
      this.searchForm.valueChanges,
      this.propertiesFilters$ ?? new Observable(),
      this.pageSize$,
      this.mainRepository.reload$
    ).subscribe({
      next: () => {
        this.page$.next(1);
      },
    });

    this.newItems$.pipe(takeUntil(this.destroy$)).subscribe((newItems) => {
      this.totalCount = (this.totalCount ?? 0) + newItems.length;
    });

    this.filterForm?.valueChanges
      .pipe(
        map((changes) =>
          Object.keys(changes).reduce(
            (result: Partial<EntityFilterForm>, key) => ({
              ...result,
              [key]: changes[key] === "" ? undefined : changes[key],
            }),
            {} as Partial<EntityFilterForm>
          )
        ),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (changes) => {
          this.propertiesFilters$?.next({
            ...this.propertiesFilters$.value,
            ...changes,
          });
        },
        error: () =>
          this.snackBarService.pushMessage(
            "Une erreur s'est produite",
            "error"
          ),
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  public loadNextPage(): void {
    if (!this.nextPageLoading) {
      this.nextPageLoading = true;
      this.page$.next(this.page$.getValue() + 1);
    }
  }

  onScrollEvent() {
    this.scroll$.next();
  }

  get filterOptionCount(): number {
    const count =
      this.searchForm.value !== "" && this.searchForm.value !== null ? 1 : 0;
    if (!this.filterForm) {
      return count;
    }
    return (
      count +
      Object.keys(this.filterForm.controls).reduce((result, controlKey) => {
        if (
          this.filterForm?.controls[controlKey].value !== "" &&
          this.filterForm?.controls[controlKey].value?.length !== 0
        ) {
          return result + 1;
        }
        return result;
      }, this.otherFilterOptionCount)
    );
  }

  get nameCapitalized(): string {
    return capitalize(this.name);
  }

  clearFilter(): void {
    this.searchForm.setValue("");

    if (!this.filterForm) {
      return;
    }
    Object.keys(this.filterForm.controls).forEach((controlKey) => {
      if (typeof this.filterForm?.controls[controlKey]?.value === "string") {
        this.filterForm?.controls[controlKey].setValue(
          "" as EntityFilterForm[string]
        );
      } else if (
        Array.isArray(this.filterForm?.controls[controlKey]?.value) &&
        this.filterForm?.controls[controlKey]?.value?.length !== 0
      ) {
        this.filterForm?.controls[controlKey].setValue(
          [] as EntityFilterForm[string][]
        );
      }
    });

    this.clear.emit();
  }

  addOne(): void {
    this.add.emit();
  }

  downloadCsv(): void {
    this.download.emit();
  }
}
