import { ElementRef, Inject, Injectable } from "@angular/core";
import { Subject } from "rxjs";

import { GOOGLE_CONFIG } from "../../config/config";
import { OauthConfig } from "../../config/config.type";
import { AbstractOauthService, } from "../../shared/services/abstract-oauth.service";
import { StorageKey, StorageService } from "../../shared/storage/storage.service";

declare const google: any;

@Injectable()
export class GoogleService extends AbstractOauthService {

  private readonly client$: Subject<void> = new Subject<void>();

  constructor(@Inject(GOOGLE_CONFIG)
              private readonly appConfig: OauthConfig,
              private readonly storageService: StorageService) {
    super(appConfig);
  }

  public async createLoginButton(elementRef: ElementRef<HTMLElement>): Promise<void> {
    // this.onSuccess = onSuccessFunction;
    this.client$.subscribe(() => {
      google.accounts.id.renderButton(elementRef.nativeElement, {
        type: 'standard',
      });
    });
    await this.loadGoogleApi();
  }

  // private handleResponse(response: GoogleResponse) {
  //   this.onSuccess(response);
  // }


  private loadGoogleApi(): Promise<void> {
    return new Promise<void>((resolve) => {
      const node = document.createElement('script');
      node.src = this.authorityUrl;
      node.type = 'text/javascript';
      document.getElementsByTagName('head')[0].appendChild(node);
      const deviceId = this.storageService.getDeviceId();
      const state = encodeURIComponent(JSON.stringify({ deviceId }));
      node.onload = () => {
        google.accounts.id.initialize({
          client_id: this.clientId,
          scope: 'email',
          auto_select: true,
          cancel_on_tap_outside: true,
          ux_mode: 'redirect',
          login_uri: `${ this.redirectUrl }`,
          state,
          prompt: 'select_account'
          // login_uri: `${ this.redirectUrl }`
        });
        this.client$.next();
        resolve();
      };
    });

  }

  logout() {
    return google.accounts.id.disableAutoSelect();
  }


}
