import { Train } from "./train.entity";

export interface ISession {
  id: string;
  date: Date;
  train: Train;
}

export class Session implements ISession {
  id!: string;

  dateString!: string;

  roomId?: string;

  get date(): Date {
    return new Date(this.dateString);
  }

  train!: Train;

}
