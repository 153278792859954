import { Component, OnDestroy, OnInit, ViewChild, ElementRef, Inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { map, Subject, switchMap, takeUntil, catchError, of } from "rxjs";

import { APP_CONFIG } from "../../config/config";
import { AppConfig } from "../../config/config.type";
import { Train } from "../../trains/models/train.entity";
import { TrainRepository } from "../../trains/repositories/train.repository";
import { MeetService } from "../meet.service";

@Component({
    selector: 'app-daily-meeting',
    templateUrl: './daily-meeting.component.html',
    styleUrls: [ './daily-meeting.component.scss' ]
})
export class DailyMeetingComponent implements OnInit, OnDestroy {
    @ViewChild('meetFrame') meetFrame!: ElementRef;

    private readonly destroy$ = new Subject<void>();

    private callFrame: any;

    isLoading = false;

    hasLeftMeeting = false;

    hasError = false;

    private currentToken: string = '';

    private currentRoomId: string = '';

    public train!: Train;

    constructor(
        private readonly meetingService: MeetService,
        private readonly trainRepository: TrainRepository,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        @Inject(APP_CONFIG)
        private readonly appConfig: AppConfig,
    ) {}

    ngOnInit() {
        this.route.paramMap
          .pipe(
            takeUntil(this.destroy$),
            switchMap(paramMap => {
              const id = paramMap.get('id');
              if (id) {
                this.isLoading = true;
                return this.trainRepository.findById(id).pipe(
                  catchError(error => {
                    console.error('Error fetching train:', error);
                    this.hasError = true;
                    this.isLoading = false;
                    return of(null);
                  })
                );
              }
              return of(null);
            }),
            switchMap(train => {
              if (train) {
                this.train = train;
              }
              if (train) {
                this.meetingService.startSession(train);
                const nextSession = train?.getNextSession(); 
                if (!nextSession?.roomId) {
                  throw new Error('No upcoming session found');
                }
                return this.meetingService.getTrainsSessionToken(train.id).pipe(
                  map(tokenResult => ({ train, token: tokenResult.token, roomId: tokenResult.roomId })),
                  catchError(error => {
                    console.error('Error fetching session token:', error);
                    this.hasError = true;
                    this.isLoading = false;
                    return of(null);
                  })
                );
              }
              return of(null);
            }),
            takeUntil(this.destroy$)
          )
          .subscribe(result => {
            if (result) {
              const { token, roomId } = result;
              this.currentToken = token;
              this.currentRoomId = roomId;
              this.initializeDailyMeeting(token, roomId);
            }
          });

        this.meetingService.getSessionEnd$()
          .pipe(
            takeUntil(this.destroy$),
            catchError(error => {
              console.error('Error ending session:', error);
              this.hasError = true;
              return of(null);
            })
          )
          .subscribe(() => {
            if (this.callFrame) {
              this.callFrame.destroy();
            }
          });
    }

    ngOnDestroy() {
        if (this.callFrame) {
            this.callFrame.destroy();
        }
        this.destroy$.next();
        this.destroy$.complete();
    }

    private initializeDailyMeeting(token: string, roomId: string) {
        this.callFrame = (window as any).DailyIframe.createFrame({
            showLeaveButton: true,
            iframeStyle: {
                position: 'fixed',
                width: '100%',
                height: '100%'
            }
        });

        this.callFrame.join({ 
            url: `${this.appConfig.dailyUrl}/${roomId}`,
            token 
        });

        this.callFrame.on('loaded', () => {
            this.isLoading = false;
        });

        this.callFrame.on('left-meeting', () => {
            this.hasLeftMeeting = true;
            this.meetingService.isMeeting = false;
            this.meetingService.closeSession(this.train.id);
        });

        this.callFrame.on('participant-left', () => {
            // this.meetingService.isMeeting = false;
            // this.meetingService.closeSession(this.train.id);
        });

        this.callFrame.on('error', (error: any) => {
            this.hasError = true;
            this.isLoading = false;
        });
    }

    rejoinMeeting() {
        this.hasLeftMeeting = false;
        this.initializeDailyMeeting(this.currentToken, this.currentRoomId);
    }

    goBack(){
        this.router.navigate([ 'trains', this.train.id ]);
    }
}