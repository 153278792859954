import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserUtils } from "@azure/msal-browser";
import { NgxPermissionsGuard } from "ngx-permissions";

import { AuthenticationComponent } from "./authentication/authentication.component";
import { AuthenticationGuardService } from "./authentication/services/authentication.guard.service";
import { ContentsMtmResolver } from './contents/resolvers/contents-mtm.resolver';
import { DashboardComponent } from "./dashboard/dashboard.component";
import { StatisticsMtmResolver } from './dashboard/resolvers/statistics-mtm.resolver';
import { MainLayoutComponent } from "./main-layout/main.layout.component";
import { DailyMeetingComponent } from './meet/daily-meeting/daily-meeting.component';
import { MeetingComponent } from "./meet/meeting/meeting.component";
import { MySpaceComponent } from "./my-space/my-space.component";
import { SwitchProfileComponent } from "./organizations/switch-profile/switch-profile.component";
import { QuestionsComponent } from "./questions/questions.component";
import { QuestionResolver } from './questions/resolvers/question.resolver';
import { MySpaceMtmResolver } from './resolvers/my-space-mtm.resolver';
import { FunnelComponent } from "./shared/components/funnel/funnel.component";
import { CanDeactivateGuard } from "./shared/guard/candeactivate.guard";
import { DynamicsDistinctionComponent } from "./thematics/dynamics-distinction/dynamics-distinction.component";
import { ThematicsMtmResolver } from './thematics/resolvers/thematics-mtm.resolver';
import { TrainCongratsComponent } from "./thematics/train-congrats/train-congrats.component";
import { AvailableTrainsComponent } from "./trains/available-trains/available-trains.component";
import { LeaveTrainComponent } from "./trains/leave-train/leave-train.component";
import { MyTrainsComponent } from "./trains/my-trains/my-trains.component";
import { AvailableTrainsResolver } from './trains/resolvers/available-trains.resolver';
import { MyTrainsMtmResolver } from './trains/resolvers/my-trains-mtm.resolver';
import { TrainResolver } from "./trains/resolvers/train.resolver";
import { TrainsMtmResolver } from './trains/resolvers/trains-mtm.resolver';
import { TrainDetailsComponent } from "./trains/train-details/train-details.component";
import { TrainsListComponent } from "./trains/trains-list/trains-list.component";
import { TypeformResponseComponent } from "./typeform/response/typeform-response.component";
import { TypeformByMailComponent } from "./typeform/typeform-by-mail/typeform-by-mail.component";
import { TypeformPageComponent } from "./typeform/typeform-page/typeform-page.component";
import { AdminDetailsComponent } from "./users/admin-details/admin-details.component";
import { MyProfileComponent } from "./users/my-profile/my-profile.component";
import { AdminResolver } from "./users/resolvers/admin.resolver";
import { SherpaResolver } from "./users/resolvers/sherpa.resolver";
import { TalkerResolver } from "./users/resolvers/talker.resolver";
import { UsersMtmResolver } from './users/resolvers/users-mtm.resolver';
import { SherpaDetailsComponent } from "./users/sherpa-details/sherpa-details.component";
import { TalkerDetailsComponent } from "./users/talker-details/talker-details.component";
import { UsersListComponent } from "./users/users-list/users-list.component";

const sherpaProfileRoutes: Routes = [
  {
    path: "",
    pathMatch: "full",
    component: SherpaDetailsComponent,
  }
];

const routes: Routes = [
  {
    path: 'meet/:id',
    pathMatch: 'full',
    component: DailyMeetingComponent,
  },
  {
    path: 'daily-meet/:id',
    pathMatch: 'full',
    component: DailyMeetingComponent,
  },
  {
    path: 'typeform/by-mail',
    pathMatch: 'full',
    runGuardsAndResolvers: 'always',
    component: TypeformByMailComponent,
    canDeactivate: [ CanDeactivateGuard ]
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [ AuthenticationGuardService ],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home'
      },
      {
        path: 'home',
        pathMatch: 'prefix',
        loadChildren: () => import('./home/home.module').then(module => module.HomeModule),
      },
      {
        path: 'my-space',
        resolve: {
          mtm: MySpaceMtmResolver
        },
        pathMatch: 'full',
        component: MySpaceComponent,
        canActivate: [ NgxPermissionsGuard ],
        data: {
          permissions: {
            only: 'talker',
            redirectTo: 'home'
          }
        }
      },
      {
        path: 'diagnostic',
        pathMatch: 'prefix',
        component: FunnelComponent,
        children: [
          {
            path: ':type',
            pathMatch: 'full',
            component: TypeformResponseComponent
          },
          {
            path: ':type/:id',
            pathMatch: 'full',
            component: TypeformResponseComponent
          }
        ]
      },
      {
        path: 'users',
        pathMatch: 'full',
        resolve: {
          mtm: UsersMtmResolver
        },
        component: UsersListComponent,
        canActivate: [ NgxPermissionsGuard ],
        data: {
          permissions: {
            only: 'admin',
            redirectTo: 'home'
          }
        }

      },
      {
        path: 'typeform/:type',
        pathMatch: 'full',
        component: TypeformPageComponent,
        canActivate: [ NgxPermissionsGuard ],
        canDeactivate: [ CanDeactivateGuard ],
        data: {
          permissions: {
            only: [ 'talker' ],
            redirectTo: 'home'
          }
        }
      }, {
        path: 'typeform/:type/:id',
        pathMatch: 'full',
        component: TypeformPageComponent,
        canActivate: [ NgxPermissionsGuard ],
        canDeactivate: [ CanDeactivateGuard ],
        data: {
          permissions: {
            only: [ 'talker' ],
            redirectTo: 'home'
          }
        }
      },
      {
        path: 'talkers/:id',
        pathMatch: 'full',
        component: FunnelComponent,
        canActivate: [ NgxPermissionsGuard ],
        data: {
          permissions: {
            only: [ 'admin', 'sherpa' ],
            redirectTo: 'home'
          }
        },
        resolve: {
          user: TalkerResolver
        },
        children: [
          {
            path: "",
            pathMatch: "full",
            component: TalkerDetailsComponent
          },
        ]
      },
      {
        path: 'admins/:id',
        pathMatch: 'full',
        component: AdminDetailsComponent,
        canActivate: [ NgxPermissionsGuard ],
        data: {
          permissions: {
            only: 'admin',
            redirectTo: 'home'
          }
        },
        resolve: {
          user: AdminResolver
        }
      },
      {
        path: 'sherpas/:id',
        pathMatch: 'full',
        component: FunnelComponent,
        resolve: {
          user: SherpaResolver
        },
        runGuardsAndResolvers: 'always',
        children: sherpaProfileRoutes
      },
      {
        path: 'organizations',
        pathMatch: 'prefix',
        canActivate: [ NgxPermissionsGuard ],
        loadChildren: () => import('./organizations/organizations.module').then(m => m.OrganizationsModule),
        data: {
          permissions: {
            only: [ 'admin', 'talker' ],
            redirectTo: 'home'
          }
        }
      },
      {
        path: 'trains',
        children: [
          {
            path: '',
            pathMatch: 'full',
            canActivate: [ NgxPermissionsGuard ],
            resolve: {
              mtm: TrainsMtmResolver
            },
            component: TrainsListComponent,
            data: {
              permissions: {
                only: 'admin',
                redirectTo: 'home'
              }
            }
          },
          {
            path: ':id',
            pathMatch: 'prefix',
            component: FunnelComponent,
            children: [ {
              path: '',
              pathMatch: "full",
              component: TrainDetailsComponent,
              resolve: {
                train: TrainResolver
              }
            }
            ]
          },

        ],
      },
      {
        path: 'my-trains',
        pathMatch: 'prefix',
        resolve: {
          mtm: MyTrainsMtmResolver
        },
        component: MyTrainsComponent,
        canActivate: [ NgxPermissionsGuard ],
        data: {
          permissions: {
            only: 'sherpa',
            redirectTo: 'home'
          }
        }
      },
      {
        path: 'available-trains',
        resolve: {
          mtm: AvailableTrainsResolver
        },
        pathMatch: 'full',
        component: AvailableTrainsComponent,
        canActivate: [ NgxPermissionsGuard ],
        runGuardsAndResolvers: 'always',
        data: {
          permissions: {
            only: [ 'talker' ],
            redirectTo: 'home'
          }
        }
      },
      {
        path: 'statistics',
        resolve: {
          mtm: StatisticsMtmResolver
        },
        pathMatch: 'full',
        component: DashboardComponent,
        canActivate: [ NgxPermissionsGuard ],
        runGuardsAndResolvers: 'always',
        data: {
          permissions: {
            only: [ 'admin', 'sherpa' ],
            redirectTo: 'home'
          }
        }

      },
      {
        path: 'resources',
        pathMatch: 'prefix',
        loadChildren: () => import('./contents/contents.module').then(m => m.ContentsModule),
      },
      {
        path: 'dynamics-distinction',
        pathMatch: 'prefix',
        component: FunnelComponent,
        data:{
          backUrl: '/thematics'
        },
        children: [ {
          path: '',
          pathMatch: 'full',
          component: DynamicsDistinctionComponent
        } ]
      },
      {
        path: 'thematics',
        resolve: {
          mtm: ThematicsMtmResolver
        },
        loadChildren: () => import('./thematics/thematics.module').then(m => m.ThematicsModule),
      },
      {
        path: 'my-profile',
        pathMatch: 'full',
        component: MyProfileComponent
      },
      {
        path: 'reservation-done',
        pathMatch: 'full',
        component: TrainCongratsComponent
      },
      {
        path: 'questions',
        pathMatch: 'full',
        resolve: {
          resolved: QuestionResolver
        },
        component: QuestionsComponent,
      },
      {
        path: 'switch-profile',
        pathMatch: 'full',
        component: SwitchProfileComponent,
        canActivate: [ NgxPermissionsGuard ],
        data: {
          permissions: {
            only: 'pilot'
          }
        }
      }
    ]
  },
  {
    path: 'leave-train',
    component: AuthenticationComponent,
    children: [
      {
        path: '',
        component: LeaveTrainComponent
      }
    ]
  },
  {
    path: 'auth',
    loadChildren: () => import('./authentication/authentication.module').then(module => module.AuthenticationModule)
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  providers: [ CanDeactivateGuard, QuestionResolver ],
  imports: [ RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload',
    initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled'
  }) ],
  exports: [ RouterModule ]
})
export class AppRouting {
}
