import { AfterViewInit, Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';

import { AbstractChartComponent } from '../abstract.chart.component';


@Component({
  selector: 'app-vertical-bar-chart',
  templateUrl: './vertical-bar-chart.component.html',
})
export class VerticalBarChartComponent extends AbstractChartComponent<'bar'> implements OnInit, AfterViewInit, OnDestroy {
    resize$ = new Subject<void>();

    type: 'bar' = 'bar';
  
    width!: number;
  
    height!: number;

    @Input() data$: any;

    data: any;

    private dataSubscription!: Subscription;


  chartOptions = {
    responsive: false,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          callback: (value: any, index: number): string => (this?.chartData?.labels ? this?.chartData?.labels[index] as string : ''),
          color: '#000',
          font: {
            size: 12,
            family: 'Arial'
          },
          minRotation: 45, // Set minimum rotation to 45 degrees
          maxRotation: 45  // Set maximum rotation to 45 degrees
        },
        grid: {
          display: false
        }
      },
      y: {
        display: false
      }
    },
    plugins: {
        legend: {
          display: false // Hide the legend
        }
    },
    datasets: {
        bar: {
          maxBarThickness: 30, // Controls maximum width of bars
          barPercentage: 0.5,  // Controls width relative to category width
          categoryPercentage: 0.8 // Controls gap between bars
        }
    }
  };

  ngOnInit() {
    this.onInit([
      this.resize$,
      ...(this.data$ && this.data$ instanceof Observable ? [this.data$] : [])
    ]);
    this.dataSubscription = this.data$?.subscribe((data: any) => {
      this.data = data;
      this.updateData();
      this.chart?.update();
    });
    this.getWidth();
    this.getHeight();
  }

  ngAfterViewInit(): void {
    if (this.chart) {
        this.chart.update();
      }
  }

  ngOnDestroy(): void {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  updateData() {
    this.chartData = {
      labels: ['de 0 à 25/100', 'de 25 à 50/100', 'de 50 à 75/100', 'de 75 à 100/100'],
      datasets: [
        {
          data: this.data?.distribution?.map((item: any) => item.count),
          backgroundColor: ['#FF4F4E', '#FC9418', '#94D82C', '#22C997']
        }
      ]
    };
  }

  @HostListener('window:resize')
  getWidth(): void {
    const MOBILE_BREAKPOINT = 741;
    const DESKTOP_PADDING = 460; 
    
    if (window.innerWidth < MOBILE_BREAKPOINT) {
      
      this.width = Math.min(Math.floor(window.innerWidth - 40), 400);
    } else {
      this.width = Math.min(Math.floor((window.innerWidth - DESKTOP_PADDING) / 3), 300);
    }
    this.resize$.next();
  }
  
  @HostListener('window:resize')
  getHeight(): void {
    const BREAKPOINT = 1100;
    const MOBILE_BREAKPOINT = 741;
    if (window.innerWidth < BREAKPOINT && window.innerWidth > MOBILE_BREAKPOINT) {
        this.height = Math.min(Math.floor(this.width * 1.2), 500);
    } else if(window.innerWidth < MOBILE_BREAKPOINT){
        this.height = Math.min(Math.floor(this.width * 0.8), 500);
    } else {
      this.height = Math.min(Math.floor(this.width), 400);
    }
    this.resize$.next();
  }
}