import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";
import { Router } from "@angular/router";

import { DialogComponent } from "../../../shared/components/dialog/dialog.component";
import { DialogRef } from "../../../shared/components/dialog/dialog.ref";
import { DIALOG_DATA } from "../../../shared/components/dialog/dialog.token";
 
export type OnBoardingData = {
  redirectButtonLabel: string;
  redirectPath: any;
  content: SafeHtml;
}

@Component({
    selector: 'app-onboarding-dialog',
    templateUrl: './onboarding-dialog.component.html',
    styleUrls: ['./onboarding-dialog.component.scss']
  })

export class OnBoardingDialogComponent extends DialogComponent<undefined, OnBoardingData>() implements OnInit, OnDestroy {

    constructor(
      @Inject(DIALOG_DATA) public override data: OnBoardingData,
      public readonly ref: DialogRef<boolean>,
      private readonly router: Router,
    ) {
    super(ref);
  }

    static override isPopIn = true;

    ngOnInit() {
        super.onInit();
    }
    
    ngOnDestroy() {
        super.onDestroy();
    }

    redirect(){
      super.close();
      setTimeout(() => {
        this.router.navigate(this.data.redirectPath);
      }, 500);
    }

}