import { addDelayToDate } from "../../shared/tool-functions/date-tools";

import { DateInterval } from "./bar-chart-tools";

export const DEFAULT_FONT = "Roboto, sans-serif";

export const DEFAULT_COLORS = [ '#707070', '#8b5eb0' , '#FF8B05', '#E37055', '#2D7DC8', '#81C64B', "#FCC417" ];

export const CUSTOM_COLORS = [ '#FF8B05', '#8b5eb0', '#2DBBB6', '#ca81e7'];

export const GENDER_COLORS = [ '#8b5eb0', '#FF8B05', '#22c997'];

export enum DateIntervalFilterOption {
  THREE_YEARS = 'three-years',
  YEAR = 'year',
  QUARTER = 'quarter',
  MONTH = 'month'
}

export function getGlobalDateIntervalByOption(interval: DateIntervalFilterOption): DateInterval {
  switch (interval) {
    case DateIntervalFilterOption.THREE_YEARS:
      return {
        fromDate: addDelayToDate('-3y'),
        toDate: new Date()
      };
    case DateIntervalFilterOption.YEAR:
      return {
        fromDate: addDelayToDate('-1y'),
        toDate: new Date()
      };
    case DateIntervalFilterOption.QUARTER:
      return {
        fromDate: addDelayToDate('-93d'),
        toDate: new Date()
      };
    case DateIntervalFilterOption.MONTH:
      return {
        fromDate: addDelayToDate('-31d'),
        toDate: new Date()
      };
    default:
      return {
        fromDate: new Date(),
        toDate: new Date()
      };
  }
}

export function getGoalColorByNote(note: number): string {
  if (note >= 0 && note <= 2) {
    return '#FF4F4E'; 
  }
  if (note > 2 && note <= 4) {
    return '#FC9418'; 
  }
  if (note > 4 && note <= 6) {
    return '#FCC418'; 
  }
  if (note > 6 && note <= 8) {
    return '#94D82C'; 
  }
  if (note > 8 && note <= 10) {
    return '#22C997'; 
  }
  return '#000000'; 
}

export function formatToOneDecimal (value: number): number {
  if (!value && value !== 0) return 0;
  return parseFloat(value.toFixed(1));
}
