import { AfterViewInit, Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { ChartOptions } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Observable, Subject, Subscription } from 'rxjs';

import { AbstractChartComponent } from '../abstract.chart.component';


@Component({
  selector: 'app-impact-chart',
  templateUrl: './impact-chart.component.html',
})
export class ImpactChartComponent extends AbstractChartComponent<'bar'> implements OnInit, AfterViewInit, OnDestroy {
    resize$ = new Subject<void>();

    type: 'bar' = 'bar';
  
    width!: number;
  
    height!: number;

    @Input() data$: any;

    @Input() counts$: any;

    data: any;

    counts: any;

    private dataSubscription!: Subscription;

    private countsSubscription!: Subscription;


    chartOptions: ChartOptions<'bar'> = {
      responsive: true,
      maintainAspectRatio: true,
      scales: {
        x: {
          ticks: {
            callback: (value: any, index: number): string => 
              (this?.chartData?.labels ? this?.chartData?.labels[index] as string : ''),
            color: '#000',
            font: {
              size: 12,
              family: 'Arial'
            },
            minRotation: 45,
            maxRotation: 45
          },
          grid: {
            display: false
          }
        },
        y: {
          display: false,
          max: 140
        }
      },
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          enabled: true,
          callbacks: {
            title: (tooltipItems) => tooltipItems[0].label,
            label: (context) => {
              const index = context.dataIndex;
              const count = this.counts ? this.counts[index] : 0;
              return [
                `Nombre de réponses: ${count}`
              ];
            }
          }
        }
      },
      datasets: {
        bar: {
          maxBarThickness: 30,
          barPercentage: 0.95,
          categoryPercentage: 0.95
        }
      }
    };

  ngOnInit() {
    this.chartPlugins = [ChartDataLabels];
    this.onInit([
        this.resize$,
        ...(this.data$ && this.data$ instanceof Observable ? [this.data$] : []),
        ...(this.counts$ && this.counts$ instanceof Observable ? [this.counts$] : [])
        ]);
    this.dataSubscription = this.data$.subscribe((data: any) => {
      this.data = data;
      this.updateData();
      this.chart?.update();
    });

    this.countsSubscription = this.counts$.subscribe((counts: any) => {
      this.counts = counts;
      this.updateData();
      this.chart?.update();
    });
    this.getWidth();
    this.getHeight();
  }

  ngAfterViewInit(): void {
    if (this.chart) {
        this.chart.update();
      }
  }

  ngOnDestroy(): void {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  updateData() {
    const labels = this.data;
    this.chartData = {
      labels: ['Avant parcours', 'Après parcours', '6 mois après parcours'],
      datasets: [
        {
          data: labels as any,
          backgroundColor: ['#BA9FD0', '#8B5FB0', '#704C8D'],
          datalabels: {
            display: true, 
            anchor: 'end',
            align: 'top' as const, 
            offset: 4, 
            color: '#000',
            font: {
              size: 12,
              family: 'Arial'
            },
            formatter: (value: any) =>`${value}/100` 
          },
        }
      ]
    };
  }

  @HostListener('window:resize')
  getWidth(): void {
    const MOBILE_BREAKPOINT = 741;
    const DESKTOP_PADDING = 460; 
    
    if (window.innerWidth < MOBILE_BREAKPOINT) {
      
      this.width = Math.min(Math.floor(window.innerWidth - 40), 400);
    } else {
      this.width = Math.min(Math.floor((window.innerWidth - DESKTOP_PADDING) / 3), 300);
    }
    this.resize$.next();
  }
  
  @HostListener('window:resize')
  getHeight(): void {
    const BREAKPOINT = 1100;
    const MOBILE_BREAKPOINT = 741;
    if (window.innerWidth < BREAKPOINT && window.innerWidth > MOBILE_BREAKPOINT) {
        this.height = Math.min(Math.floor(this.width * 1.2), 500);
    } else if(window.innerWidth < MOBILE_BREAKPOINT){
        this.height = Math.min(Math.floor(this.width * 0.8), 500);
    } else {
      this.height = Math.min(Math.floor(this.width), 400);
    }
    this.resize$.next();
  }
}