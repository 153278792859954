import { Component, OnInit } from "@angular/core";

import { RESPONSE_DATA } from "../response/response.data";
import { TypeformService } from "../typeform.service";
import {
  PostSessionFormResponse,
  TypeformFormNeeded,
  TypeformFormType,
  TypeformResponseWithPageResult,
} from "../typeform.type";

@Component({
  selector: "app-typeform-responses-panel",
  templateUrl: "./typeform-responses-panel.component.html",
  styleUrls: ["./typeform-responses-panel.component.scss"],
})
export class TypeformResponsesPanelComponent implements OnInit {
  typeformNeeded?: Exclude<TypeformFormNeeded, PostSessionFormResponse>;

  responses: TypeformResponseWithPageResult[] = [];

  notificationTitle?: string;

  notificationContent: string = "";

  notificationButtonMessage?: string;

  omsMoodColor = RESPONSE_DATA.omsMoodData.color;

  flourishingColor = RESPONSE_DATA.flourishingData.color;

  workSatisfactionColor = RESPONSE_DATA.workSatisfactionData.color;

  constructor(public readonly typeformService: TypeformService) {}

  ngOnInit() {
    this.typeformNeeded = this.getTypeformFormResponseIsNeeded();
    this.responses = [
      this.typeformService.initialFormResponse,
      ...this.typeformService.ponctualFormResponses,
      ...this.typeformService.postTrainFormResponses,
      ...(this.typeformService.sixMonthsPostFirstTrainFormResponses ?? []),
    ]
    .filter((response): response is TypeformResponseWithPageResult => !!response)
    .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
  }

  getTypeformFormResponseIsNeeded(): TypeformFormNeeded | undefined {
    if (!this.typeformService.initialFormResponse) {
      this.notificationTitle = "Faites le point sur votre bien-être";
      this.notificationContent = this.typeformService.talker.trainMemberships
        .length
        ? "Il n’est jamais trop tard pour bien faire, réalisez dès maintenant votre diagnostic bien-être initial…"
        : "Réalisez le diagnostic bien-être initial selon 3 thématiques : affects, satisfaction de vie et relations au travail.\n" +
          "  We talk vous accompagnera ensuite pour progresser.";
      this.notificationButtonMessage = "Réaliser mon bilan émotionnel";
      return { type: TypeformFormType.INITIAL };
    }

    if (this.typeformService.trainsWithoutResponse.length) {
      this.notificationTitle = "Faites le bilan de votre parcours";
      this.notificationContent =
        "Nous sommes ravis de vous avoir accompagné tout au long de votre parcours.\n Comment vous sentez-vous après cette expérience ? Exprimez-vous en faisant votre bilan émotionnel ici.";
      this.notificationButtonMessage = "Réaliser le bilan";
      return {
        type: TypeformFormType.POST_TRAIN,
        train: this.typeformService.trainsWithoutResponse[0],
      };
    }

    if (
      this.typeformService.isSixMonthsAfterFirstTrain &&
      !this.typeformService.sixMonthsPostFirstTrainFormResponses &&
      this.typeformService.firstTrain
    ) {
      this.notificationTitle =
        "Comment allez-vous depuis votre dernier parcours ?";
      this.notificationContent =
        "Cela fait 6 mois que vous avez fini votre premier parcours\n Comment vous sentez-vous après tout ce temps ? Exprimez-vous en faisant votre bilan émotionnel ici.";
      this.notificationButtonMessage = "Réaliser le bilan";

      return {
        type: TypeformFormType.SIX_MONTHS_POST_FIRST_TRAIN,
        train: this.typeformService.firstTrain,
      };
    }

    return undefined;
  }

  goToNeededTypeformForm() {
    if (this.typeformNeeded) {
      if(this.typeformNeeded.type === TypeformFormType.INITIAL){
        (window._mtm || []).push({ 'event': 'demarrage-bilan' });
      }
      this.typeformService.goToSpecificForm(this.typeformNeeded);
    }
}

  goToResult(response: TypeformResponseWithPageResult) {
    this.typeformService.goToResult(response);
  }

  getTypeformTitle(
    type: Exclude<TypeformFormType, TypeformFormType.POST_SESSION>
  ): string {
    if (type === TypeformFormType.INITIAL) {
      return "Bilan initial";
    }
    if (type === TypeformFormType.PONCTUAL) {
      return "Bilan de suivi";
    }
    if (type === TypeformFormType.POST_TRAIN) {
      return "Bilan après votre accompagnement";
    }
    if (type === TypeformFormType.SIX_MONTHS_POST_FIRST_TRAIN) {
      return "Bilan 6 mois après votre accompagnement";
    }
    return "";
  }

  round(value: number): number {
    return Math.round(value);
  }
}
