import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';

import { DynamicType } from "../../../thematics/models/dynamic.model";
import { Thematic } from "../../../thematics/models/thematic.model";
import { TrainMembership } from "../../../trains/models/membership.entity";
import { DEFAULT_FONT } from "../../utils/utils";
import { AbstractChartComponent } from "../abstract.chart.component";

type ThematicData = {
  id: string;
  name: string;
  coaching: number;
  speech: number;
}

@Component({
  selector: 'app-thematic-distribution',
  templateUrl: './thematic-distribution.component.html',
})
export class ThematicDistributionComponent extends AbstractChartComponent<'bar'> implements OnChanges, OnDestroy {

  @Input() trainMemberships!: TrainMembership[];

  @Input() thematics: Thematic[] | null = [];

  type: "bar" = "bar";

  chartOptions = {
    responsive: false,
    maintainAspectRatio: false,
    indexAxis: 'y' as const,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true
      }
    },
    plugins: {
      legend: {
        labels: {
          font: {
            family: DEFAULT_FONT
          }
        },
        position: 'bottom' as const
      },
    }
  };


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.thematics && !changes.thematics.previousValue) {
      this.onInit();
    }
    super.onChanges();
  }

  ngOnDestroy() {
    this.onDestroy();
  }

  updateData() {
    const trainsPerThematic: ThematicData[] = this.trainMemberships
      .map(membership => membership.train)
      .filter((train, index, self) => 
        index === self.findIndex((t) => t.id === train.id)
      )
      .reduce((result, train) => {
        const trainThematic = this.thematics?.find(thematic => thematic.dynamics?.some(dynamic => dynamic.id === train.dynamic.id));
        const trainDynamic = trainThematic?.dynamics.find(dynamic => dynamic.id === train.dynamic.id);
        if (!trainThematic) {
          return result;
        }

        let thematicOfResult = result.find(thematicData => thematicData.id === trainThematic.id);
        const thematicFound = !!thematicOfResult;
        if (!thematicFound) {
          thematicOfResult = {
            name: trainThematic.name,
            id: trainThematic.id,
            coaching: 0,
            speech: 0
          };
        }

        if (trainDynamic?.type === DynamicType.COACHING) {
          thematicOfResult!.coaching++;
        } else {
          thematicOfResult!.speech++;
        }

        if (!thematicFound) {
          result.push(thematicOfResult!);
        }

        return result;
      }, [] as ThematicData[])
      .sort((dataA, dataB) => (dataB.speech + dataB.coaching) - (dataA.speech + dataA.coaching));

    this.chartData.labels = trainsPerThematic.map(thematicData => thematicData.name);

    this.chartData.datasets = [
      {
        label: 'Coaching en groupe',
        data: trainsPerThematic.map(thematicData => thematicData.coaching),
        backgroundColor: this.getDefaultColors()[1],
        borderColor: this.getDefaultColors()[1]
      },
      {
        label: 'Parole en groupe',
        data: trainsPerThematic.map(thematicData => thematicData.speech),
        backgroundColor: this.getDefaultColors()[2],
        borderColor: this.getDefaultColors()[2]
      }

    ];
  }

  get width(): number {
    return Math.min(window.innerWidth - 600, 600);
  }

  get height(): number {
    return Math.floor(this.width * 2 / 3);
  }
}
