import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { BehaviorSubject, combineLatestWith, filter, Observable, Subject, switchMap, takeUntil, tap } from "rxjs";

import { InPageTab } from "../../shared/components/in-page-tabs/in-page-tabs/in-page-tabs.component";
import { capitalize } from "../../shared/tool-functions/capitalize";
import { Train } from "../../trains/models/train.entity";
import { Role } from "../../users/models/users.entity";
import { ProfileService } from "../../users/services/profile.service";
import { Organization, OrganizationDataResume } from "../models/organizations.entity";
import { OrganizationRepository } from "../repositories/organization.repository";
import { OrganizationStoreService } from "../services/organization.store.service";

interface ImagesLinks {
  "tree-in-head": string;
  coach: string;
  train: string;
}

@Component({
  selector: 'app-my-organization-home',
  templateUrl: './my-organization-home.component.html',
  styleUrls: ['./my-organization-home.component.scss']
})
export class MyOrganizationHomeComponent implements OnInit, OnDestroy {
  firstName!: string;

  role!: Role;

  destroy$ = new Subject<void>();

  trains: Train[] = [];

  loading = true;

  isPilot = false;

  organization!: Organization;

  organizationDataResume!: OrganizationDataResume;

  tabs: InPageTab[] = [
    {
      icon: "users",
      color: "#2D7DC8",
      title: "Collaborateurs",
      description: "Invitez et administrez les utilisateurs ",
      link: "talkers"
    },
    {
      icon: "chart",
      color: "#FF8B05",
      title: "Statistiques",
      description: "Suivez les usages et les impacts positifs",
      link: "statistics"
    },
    {
      icon: "parameters",
      color: "#2CBBB6",
      title: "Paramètres",
      description: "Gérez les informations de votre compte",
      link: "edit"
    },
    {
      icon: "crawn",
      color: "#E37055",
      title: "Crédits",
      description: "Pilotez votre consommation",
      link: "units"
    },
  ];

  private readonly imagesLinksSubject = new BehaviorSubject<ImagesLinks>({
    "tree-in-head": '',
    coach: '',
    train: ''
  });

  imagesLinks$: Observable<ImagesLinks> = this.imagesLinksSubject.asObservable();

  constructor(private readonly profileService: ProfileService,
              private readonly router: Router,
              private readonly titleService: Title,
              private readonly organizationRepository: OrganizationRepository,
              private readonly organizationStoreService: OrganizationStoreService,
              private readonly cdr: ChangeDetectorRef // Inject ChangeDetectorRef

  ) {
  }

  ngOnInit(): void {
    this.titleService.setTitle('We Talk');
    this.profileService.getObservable()
      .pipe(
        combineLatestWith(
          this.organizationStoreService.getObservable()
            .pipe(
              tap(organization => {
                this.organization = organization!;
              }),
              filter((organization): organization is Organization => !!organization),
              switchMap(organization => this.organizationRepository.getOrganizationDataResume(organization.id)))
        ),
        takeUntil(this.destroy$))
      .subscribe(([ profile, organizationDataResume ]) => {
        this.firstName = capitalize(profile.firstName);
        this.isPilot = profile.role === Role.Talker && profile.isPilot;
        this.organizationDataResume = organizationDataResume;
        this.profileService.getHomeImagesLinks().subscribe((images) => {
          const imagesLinks: ImagesLinks = {
            "tree-in-head": images["tree-in-head"] || '',
            coach: images.coach || '',
            train: images.train || ''
          };
          this.imagesLinksSubject.next(imagesLinks);
        });
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }


  protected readonly capitalize = capitalize;

}
