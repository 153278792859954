<div class="padding-wrapper">
  <div class="wrapper">
    <h1 class="margin-bottom text-primary">
      Bienvenue {{ capitalize(firstName) }}
    </h1>
    <!--    <div class="home-notif" *ngxPermissionsOnly="['talker']">-->
    <!--      <app-notification [outline]="true"  class="margin-bottom" [buttonMessage]=" hasDoneInitialResponse ? 'Accéder à mon espace' : 'Réaliser mon bilan émotionnel'" (onButtonClick)="goToMySpace()" color="#8C5FB0" [icon]="hasDoneInitialResponse ? 'check' : 'bell'" title="Faites le point sur votre évolution">-->
    <!--        {{hasDoneInitialResponse ? 'Vous avez réalisé le bilan initial. C’est dans votre espace personnel que vous pourrez consulter l’évolution de votre état émotionnel.' : 'Réalisez votre bilan émotionnel pour identifier les parcours qui vous correspondent.'}}-->
    <!--      </app-notification>-->
    <!--    </div>-->

    <app-notifications-resume />
    <ng-container *ngxPermissionsOnly="'talker'">
      <h1 class="margin-top margin-bottom">Comment We Talk peut vous aider aujourd'hui ?</h1>
      <section class="hero-section">
        <div class="onboarding-container">
          <div class="row right-align">
            <div class="onboarding-item">
              <div class="clickable-icon" (click)="openOnboardingDialog('progress')">
                <app-icon type="onboarding-chart" size="200"/>
              </div>  
              <button (click)="openOnboardingDialog('progress')" class="onboarding-button primary">Je veux progresser</button>
            </div>
          </div>
      
          <div class="row center-align">
            <div class="onboarding-item">
              <div (click)="openOnboardingDialog('trial')" class="clickable-icon">
                <app-icon type="onboarding-rain" size="200"/>
              </div>
              <button (click)="openOnboardingDialog('trial')" class="onboarding-button secondary">
                <div>Je ne me sens pas bien,</div> 
                <div>je traverse une épreuve</div>
              </button>
            </div>
          </div>
      
          <div class="row space-between">
            <div class="onboarding-item">
              <button (click)="openOnboardingDialog('lost')" class="onboarding-button black">Je ne sais pas par où commencer</button>
              <div class="clickable-icon" (click)="openOnboardingDialog('lost')">
                <app-icon type="onboarding-question" size="200"/>
              </div>
            </div>
            <div class="onboarding-item">
              <div class="clickable-icon" (click)="openOnboardingDialog('explore')">
                <app-icon type="onboarding-chatting" size="200"/>
              </div>
              <button (click)="openOnboardingDialog('explore')" class="onboarding-button indigo">Je veux explorer et découvrir</button>
            </div>
          </div>
        </div>
      </section>
    </ng-container>

    <div *ngxPermissionsOnly="'talker'" class="testimony-section">
      <div class="text-medium text-center text-bold margin-top margin-bottom">Témoignage du mois</div>
      <div class="testimony-container">
        <div class="video-column">
          <div class="video-wrapper">
            <youtube-player [playerVars]="playerVars" disableCookies="true" videoId="wH3-5xIQOjk"></youtube-player>
          </div>
        </div>
        <div class="text-column">
          <div class="testimony-quote">
            <app-icon type="onboarding-citation" size="30" />
            <p class="testimony-top">
              J'ai vécu une situation personelle très difficile pendant pas mal de temps, et quand je suis
              sortie de cette situation, j'ai vraiment eu envie de me dire "À quoi je me dédie maintenant, en 
              cohérence avec ce qui compte pour moi ?". 
            </p>
          </div>
          <p class="testimony-bottom">
            Anne a suivi un parcours sur la thématique "Trouver du sens à ma vie"
          </p>
        </div>
      </div>
    </div>

    <app-home-page-tabs />

    <div *ngxPermissionsOnly="'admin'" class="base-margin column gap">
      <h3>Configuration admin</h3>
      <div class="row gap space-between">
        <p *ngIf="smsBalance" [class.color-danger]="smsBalance < 5">
          Solde SMS Vonage: {{ smsBalance }}€
        </p>
        <div
          *ngIf="canDeactivateSendingEmail"
          class="row center-children gap space-between"
        >
          <span>Envoi des mails</span>
          <app-checkbox
            (ngModelChange)="onSwitchChange($event)"
            [ngModel]="isSendingEmailActivated"
            [type]="'switch'"
          />
        </div>
      </div>
      <app-button
        [outline]="true"
        (onClick)="editPlatformConfiguration()"
        title="Modifier la configuration"
        >Modifier la configuration
      </app-button>
      <app-button
        [outline]="true"
        (onClick)="editWeeklyTimeSlots()"
        title="Modifier les créneaux"
        >Modifier les créneaux horaires des séances
      </app-button>
      <app-button
        *ngIf="canResetData"
        [danger]="true"
        [outline]="true"
        (onClick)="restTestData()"
        title="Réinitialiser les données"
        >Réinitialiser les données <span *ngIf="resetDataLoading">...</span>
      </app-button>
    </div>

    <div class="margin-top-xl" *ngxPermissionsExcept="'admin'">
      <h1 class="margin-bottom">Votre démarche commence ici</h1>
      <app-welcome-links [images$]="imagesLinks$" [role]="role" />
    </div>
  </div>
</div>
