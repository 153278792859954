import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import {  SharedModule } from '../shared/shared.module';

import { DailyMeetingComponent } from './daily-meeting/daily-meeting.component';
import { MeetService } from "./meet.service";
import { MeetingComponent } from './meeting/meeting.component';



@NgModule({
  declarations: [
    MeetingComponent,
    DailyMeetingComponent
  ],
  imports: [
    CommonModule,
    SharedModule
],
  exports: [
    MeetingComponent,
    DailyMeetingComponent
  ],
  providers: [ MeetService ]
})
export class MeetModule { }
