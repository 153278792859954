import { Component } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

import { AbstractInFunnelComponent } from "../../shared/components/funnel/abstract.in-funnel.component";
import { FunnelService } from "../../shared/services/funnel.service";
import { TypeformService } from "../typeform.service";
import {
  TypeformFormType,
  TypeformResponseWithPageResult
} from "../typeform.type";

import { RESPONSE_DATA } from "./response.data";

@Component({
  selector: 'app-typeform-response',
  templateUrl: './typeform-response.component.html',
  styleUrls: [ './typeform-response.component.scss' ]
})
export class TypeformResponseComponent extends AbstractInFunnelComponent {

  typeformResponse!: TypeformResponseWithPageResult;

  override previousMessage = 'Retour';

  title!: string;

  omsMoodResult = RESPONSE_DATA.omsMoodData;

  flourishingResult = RESPONSE_DATA.flourishingData;

  workSatisfactionResult = RESPONSE_DATA.workSatisfactionData;

  constructor(private readonly router: Router,
              private readonly typeformService: TypeformService,
              protected override readonly route: ActivatedRoute,
              protected override readonly funnelService: FunnelService) {
    super(funnelService, route);
  }


  override ngOnInit() {
    this.route.paramMap.subscribe(paramMap => {
      this.previousMessage = 'Retour';
      const type: TypeformFormType = paramMap.get('type') as TypeformFormType ?? TypeformFormType.INITIAL;
      const id: string | null = paramMap.get('id');

      if (type === TypeformFormType.INITIAL && this.typeformService.initialFormResponse) {
        this.typeformResponse = this.typeformService.initialFormResponse;
        this.title = 'Votre diagnostic bien-être';
      }
      if (type === TypeformFormType.PONCTUAL) {
        const response = this.typeformService.ponctualFormResponses.find(r => r.id === id);
        if (response) {
          this.title = 'Votre bilan ponctuel';
          this.typeformResponse = response;
        }
      }
      if (type === TypeformFormType.SIX_MONTHS_POST_FIRST_TRAIN && this.typeformService.sixMonthsPostFirstTrainFormResponses?.length) {
        const response = this.typeformService.sixMonthsPostFirstTrainFormResponses.find(r => r.trainId === id);
        if (response) {
          this.title = 'Votre bilan après 6 mois';
          this.typeformResponse = response;
        }
      }
      if (type === TypeformFormType.POST_TRAIN) {
        const response = this.typeformService.postTrainFormResponses.find(r => r.trainId === id);
        if (response) {
          this.title = 'Votre bilan de parcours';
          this.typeformResponse = response;
        }
      }

      if (this.typeformResponse === undefined) {
        this.router.navigate(['home']);
      }
    });
  }

  goToThematics() {
    this.router.navigate([ 'thematics' ]);
  }

  round(value: number): number {
    return Math.round(value);
  }
}
