<div class="wrapper">
  <h1 class="margin-bottom text-primary">Bienvenue {{capitalize(firstName)}}</h1>
  <div class="notification-resume-wrapper column">
    <div class="resume-container">
     <app-notifications-resume />
    </div>

    <div class="resume-wrapper" *ngIf="organizationDataResume">
      <div class="resume row">
        <div class="tile">
          <div class="cooper text-large">Engagement</div>
        </div>
        <div class="tile">
          <div class="text-xl text-primary cooper">{{organizationDataResume.members}}</div>
          <div class="cooper text-black">invité{{organizationDataResume.members > 1 ? 's' : ''}}</div>
        </div>
        <div class="tile">
          <div class="text-xl text-primary cooper">{{organizationDataResume.activatedMembers}}</div>
          <div class="cooper text-black">inscrit{{organizationDataResume.activatedMembers > 1 ? 's' : ''}}</div>
        </div>
        <div class="tile">
          <div class="text-xl text-primary cooper">{{organizationDataResume.talkers}}</div>
          <div class="cooper text-black">talker{{organizationDataResume.talkers > 1 ? 's' : ''}}</div>
        </div>
      </div>
    </div>



      <div class="resume row " *ngIf="organizationDataResume && organizationDataResume.relativesRecommendationPercent
  && organizationDataResume.goalsAchievementPercent && organizationDataResume.trainSatisfactionPercent">
        <div class="tile">
          <div class="cooper text-large">Impact</div>
        </div>
        <div class="tile">
          <div class="text-xl text-primary cooper row">{{organizationDataResume.trainSatisfactionPercent}} %</div>
          <div class="cooper text-black">satisfaction parcours</div>
        </div>
        <div class="tile">
          <div class="text-xl text-primary cooper row">{{organizationDataResume.relativesRecommendationPercent}} %</div>
          <div class="cooper text-black">recommandation</div>
        </div>
        <div class="tile">
          <div class="text-xl text-primary cooper row">{{organizationDataResume.goalsAchievementPercent}} %</div>
          <div class="cooper text-black">objectifs atteints</div>
        </div>
      </div>
  </div>



  <div class="margin-bottom">
    <app-home-page-tabs />
  </div>

  <div class="margin-top-xl">
    <h1 class="margin-bottom">En savoir plus sur We Talk</h1>
    <app-welcome-links [images$]="imagesLinks$" role="pilot" />
  </div>

</div>
