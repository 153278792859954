<div class="mobile-column gap">
  <div *ngFor="let link of links" class="home-welcome-tile column gap pointer" (click)="clickOnLink(link)">
    <div class="image">
      <app-image [alt]="link.image" [src]="link.image"/>
    </div>
    <h2 class="cooper">{{ link.title }}.</h2>
    <strong class="link-subtitle text-primary cooper">{{ link.subTitle }}</strong>
    <p class="text-medium">{{ link.description }}</p>

    <app-icon type="arrow-right" class="rounded-icon"></app-icon>
  </div>
</div>
