import { Type } from "class-transformer";

import { Organization } from "../../organizations/models/organizations.entity";
import { Thematic } from "../../thematics/models/thematic.model";
import { TrainMembership } from "../../trains/models/membership.entity";
import {
  InitialFormResponse,
  PonctualFormResponse,
  PostSessionFormResponse,
  PostTrainFormResponse,
  SixMonthsPostFirstTrainFormResponse
} from "../../typeform/typeform.type";

import { Role, User } from "./users.entity";

export class OrganizationPilot {
  @Type(() => Organization)
  organization?: Organization;

  @Type(() => Talker)
  pilot?: Talker;

  @Type(() => Date)
  createdAt!: Date;

}

export class Talker extends User {
  override role!: Role.Talker;

  @Type(() => TrainMembership)
  trainMemberships!: TrainMembership[];

  @Type(() => Organization)
  organization!: Organization;


  @Type(() => OrganizationPilot)
  organizationAssociations!: OrganizationPilot[];

  @Type(() => InitialFormResponse)
  initialFormResponse?: InitialFormResponse;

  @Type(() => PonctualFormResponse)
  ponctualFormResponses?: PonctualFormResponse;

  @Type(() => PostTrainFormResponse)
  postTrainFormResponses?: PostTrainFormResponse[];

  @Type(() => PostSessionFormResponse)
  postSessionFormResponses?: PostSessionFormResponse[];

  @Type(() => SixMonthsPostFirstTrainFormResponse)
  sixMonthsPostFirstTrainFormResponses?: SixMonthsPostFirstTrainFormResponse[];

  @Type(() => Thematic)
  favoriteThematics?: Thematic[];

  get isPilot(): boolean {
    return this.organizationAssociations && !!this.organizationAssociations.length;
  }
}
