// eslint-disable-next-line max-classes-per-file
import { Type } from "class-transformer";

import { Session } from "../trains/models/session.entity";
import { Train } from "../trains/models/train.entity";

export enum TypeformFormType {
  INITIAL = 'initial',
  PONCTUAL = 'ponctual',
  POST_SESSION = 'post-session',
  POST_LAST_SESSION = 'post-last-session',
  POST_TRAIN = 'post-train',
  SIX_MONTHS_POST_FIRST_TRAIN = 'six-months-post-first-train'
}

export class AbstractTypeformFormResponse {

  id!: string;

  type!: TypeformFormType;

  source?: 'platform' | 'email';

  talkerId!: string;

  @Type(() => Date)
  createdAt!: Date;
}

export interface TypeformResponseWithComputedValues {
  affectAndRelation: number;
  lifeSatisfaction: number;
  workSatisfaction: number;
}

export class InitialFormResponse extends AbstractTypeformFormResponse implements TypeformResponseWithComputedValues {
  override type = TypeformFormType.INITIAL as const;

  lifeSatisfaction!: number;

  affectAndRelation!: number;

  workSatisfaction!: number;
}

export class PonctualFormResponse extends AbstractTypeformFormResponse implements TypeformResponseWithComputedValues {
  override type = TypeformFormType.PONCTUAL as const;

  lifeSatisfaction!: number;

  affectAndRelation!: number;

  workSatisfaction!: number;
}

export class PostSessionFormResponse extends AbstractTypeformFormResponse  {
  override type = TypeformFormType.POST_SESSION as const;

  sessionId!: string;

  sessionSatisfaction!: number;

}

export class PostTrainFormResponse extends AbstractTypeformFormResponse implements TypeformResponseWithComputedValues {
  override type = TypeformFormType.POST_TRAIN as const;

  trainId!: string;

  lifeSatisfaction!: number;

  affectAndRelation!: number;

  workSatisfaction!: number;

  trainSatisfaction!: number;

  sessionSatisfaction!: number;

  sherpaSatisfaction!: number;

  wantToDoAnotherTrain!: boolean;

  relativesRecommendation!: number;

  thematicProgress!: number;

  goalsAchievement!: number;
}

export class SixMonthsPostFirstTrainFormResponse extends AbstractTypeformFormResponse implements TypeformResponseWithComputedValues {
  override type = TypeformFormType.SIX_MONTHS_POST_FIRST_TRAIN as const;

  trainId!: string;

  lifeSatisfaction!: number;

  affectAndRelation!: number;

  workSatisfaction!: number;
}

export type TypeformFormResponse =
  InitialFormResponse
  | PonctualFormResponse
  | PostSessionFormResponse
  | PostTrainFormResponse
  | SixMonthsPostFirstTrainFormResponse;


export type TypeformTokenPayload = {
  userId: string;
  type: TypeformFormType;
  trainId?: string;
  thematicName?: string;
  date?: string;
}

export type TypeformHiddenFields = {
  source: 'platform' | 'email' | 'preprod',
  id_user: string;
  id_parcours?: string;
  id_session?: string;
  date_latest_session?: string;
  theme_title_fr?: string;
}


export type InitialFormNeeded = {
  type: TypeformFormType.INITIAL;
  isLastSession?: boolean;
}

export type PostSessionFormNeeded = {
  type: TypeformFormType.POST_SESSION;
  session: Session;
  isLastSession?: boolean;
}

export type  PostTrainFormNeeded = {
  type: TypeformFormType.POST_TRAIN;
  train: Train;
  isLastSession?: boolean;
}

export type SixMonthsAfterFirstTrainFormNeeded = {
  type: TypeformFormType.SIX_MONTHS_POST_FIRST_TRAIN;
  train: Train;
  isLastSession?: boolean;
}

export type TypeformFormNeeded =
  InitialFormNeeded
  | PostSessionFormNeeded
  | PostTrainFormNeeded
  | SixMonthsAfterFirstTrainFormNeeded;

export type TypeformResponseWithPageResult = Exclude<TypeformFormResponse, PostSessionFormResponse>

export class TypeformResponseFromApi {

  @Type(() => Object, {
    keepDiscriminatorProperty: true,
    discriminator: {
      property: "type",
      subTypes: [
        { value: PostSessionFormResponse, name: TypeformFormType.POST_TRAIN },
        { value: InitialFormResponse, name: TypeformFormType.INITIAL },
        { value: SixMonthsPostFirstTrainFormResponse, name: TypeformFormType.SIX_MONTHS_POST_FIRST_TRAIN },
      ]
    }
  })
  data!: TypeformResponseWithPageResult | null;
}
