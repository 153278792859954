import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { plainToInstance } from "class-transformer";
import { filter, Observable, of, startWith, Subject, switchMap, tap } from "rxjs";
import { map } from "rxjs/operators";

import { APP_CONFIG } from "../../config/config";
import { AppConfig } from "../../config/config.type";
import { TimeSlot } from "../../trains/models/time-slot.entity";
import { Admin } from "../models/admin.entity";
import { Sherpa } from "../models/sherpa.entity";
import { Talker } from "../models/talker.entity";
import { IUpdateUser, Role, User } from "../models/users.entity";


export type Profile = Admin | Talker | Sherpa;

@Injectable({ providedIn: 'root' })
export class ProfileService {
  private readonly profile$: Subject<Profile> = new Subject<Profile>();

  private readonly apiUrl: string;

  private isLoading = false;

  public profile?: Profile;

  public imageUpdate$ = new Subject<string>();

  private serviceName = 'my-profile';

  constructor(
    @Inject(APP_CONFIG)
    private readonly appConfig: AppConfig,
    private readonly http: HttpClient) {
    this.apiUrl = this.appConfig.apiUrl;

    this.profile$.pipe(filter(state => !!state?.role)).subscribe(state => {
      this.profile = state;
    });
  }

  loadProfile(): Observable<Profile> {
    this.isLoading = true;
    const lastConnectionDate = localStorage.getItem('lastConnectionDate');
    
    let url = [this.apiUrl, this.serviceName].join('/');
    if (lastConnectionDate) {
      url += `?lastConnectionDate=${encodeURIComponent(lastConnectionDate)}`;
    }
    return this.http.get<Profile>(url)
      .pipe(map(result => {
          localStorage.setItem('lastConnectionDate', result?.lastConnectionDateString as any);
          if (result.role === Role.Sherpa) {
            return plainToInstance(Sherpa, result);
          }
          if (result.role === Role.Talker) {
            return plainToInstance(Talker, result);
          }
          return plainToInstance(Admin, result);
        }),
        tap((profile: Profile) => {
          this.profile$.next(profile);
          this.isLoading = false;
        }));
  }

  getObservable(): Observable<Profile> {
    return this.profile$.asObservable()
      .pipe(startWith(this.profile),
        filter((profile): profile is Profile => !!profile)
      );
  }

  getMyProfile(): Observable<Profile> {
    if (this.profile) {
      return of(this.profile);
    }
    if (this.isLoading) {
      return this.getObservable();
    }
    return this.loadProfile();
  }

  updateProfile(payload: Partial<IUpdateUser>) {
    return this.http.patch<void>([ this.apiUrl, this.serviceName ].join('/'), { ...payload })
      .pipe(switchMap(() => this.loadProfile()));
  }

  logout(): void {
    this.profile = undefined;
  }

  updateMyTimeSlotPreferences(timeSlots: TimeSlot[]): Observable<User> {
    return this.http.post<User>([ this.apiUrl, this.serviceName, 'my-timeslot-preferences' ].join('/'), { timeSlots })
      .pipe(switchMap(() => this.loadProfile()));
  }

  deleteAccount(): Observable<void> {
    return this.http.delete<void>([ this.apiUrl, this.serviceName ].join('/'));
  }

  deletePresentingVideo(): Observable<void> {
    return this.http.delete<void>([ this.apiUrl, this.serviceName, 'delete-video' ].join('/'));
  }

  deleteDescription(): Observable<void> {
    return this.http.delete<void>([ this.apiUrl, this.serviceName, 'delete-description' ].join('/'));
  }

  orientMe({ message, phoneNumber, callTimePreference }: { message: string, phoneNumber: string, callTimePreference: string}): Observable<void> {
    return this.http.post<void>([ this.apiUrl, this.serviceName, 'orient-me' ].join('/'), { message, phoneNumber, callTimePreference });
  }

  getHomeImagesLinks(): Observable<{ [key: string]: string }> {
    return this.http.get<{ [key: string]: string }>([ this.apiUrl, this.serviceName, 'home-images-links' ].join('/'));
  }  

}
