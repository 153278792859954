<div *ngIf="typeformResponse">
  <h1 class="cooper margin-bottom">{{ title }}</h1>
  <p class="text-black margin-bottom"><strong>Merci d'avoir pris le temps de vous auto-évaluer !</strong></p>
  <p>Bilan réalisé le {{typeformResponse.createdAt.toLocaleDateString()}}</p>
  <p class="text-black">Les scores obtenus sont purement indicatifs et ne constituent en aucun cas un diagnostic médical. Nous vous
    encourageons à vous rapprocher d'un professionnel de santé dès lors que vous en ressentez le besoin.</p>

  <div *ngIf="typeformResponse.affectAndRelation">

    <h2 class="cooper margin-bottom margin-top">Affects</h2>
    <div class="tile row gap">
      <div class="result center-children column" [style.color]="omsMoodResult.color">
        <app-icon type="heart" />
        <div class="text-center row">
          <div class="text-xl cooper">{{round(typeformResponse.affectAndRelation)}}</div>
          <div class="cooper bottom">/ {{omsMoodResult.maximum}}</div>
        </div>
      </div>
      <div class="column gap center-children-horizontally">
        <h3 class="cooper">{{typeformResponse.affectAndRelation >= omsMoodResult.frontier ? omsMoodResult.titleHigh : omsMoodResult.titleLow}}</h3>
        <div >{{typeformResponse.affectAndRelation >= omsMoodResult.frontier ? omsMoodResult.descriptionHigh : omsMoodResult.descriptionLow}}</div>
      </div>
    </div>
  </div>
  <div *ngIf="typeformResponse.lifeSatisfaction">
    <h2 class="cooper margin-bottom margin-top">Satisfaction de vie</h2>
    <div class="tile row gap">
      <div class="result center-children column" [style.color]="flourishingResult.color">
        <app-icon type="smile" />
        <div class="text-center row">
          <div class="text-xl cooper">{{round(typeformResponse.lifeSatisfaction)}}</div>
          <div class="cooper bottom">/ {{flourishingResult.maximum}}</div>
        </div>
      </div>
      <div class="column gap center-children-horizontally">
        <h3 class="cooper">{{typeformResponse.lifeSatisfaction >= flourishingResult.frontier ? flourishingResult.titleHigh : flourishingResult.titleLow}}</h3>
        <div >{{typeformResponse.lifeSatisfaction >= flourishingResult.frontier ? flourishingResult.descriptionHigh : flourishingResult.descriptionLow}}</div>
      </div>
    </div>
  </div>
  <div *ngIf="typeformResponse.workSatisfaction">

    <h2 class="cooper margin-bottom margin-top">Relation au travail</h2>
    <div class="tile row gap">
      <div class="result center-children column" [style.color]="workSatisfactionResult.color">
        <app-icon type="suit-case" />
        <div class="text-center row">
          <div class="text-xl cooper">{{round(typeformResponse.workSatisfaction)}}</div>
          <div class="cooper bottom">/ {{workSatisfactionResult.maximum}}</div>
        </div>
      </div>
      <div class="column gap center-children-horizontally">
        <h3 class="cooper">{{typeformResponse.workSatisfaction >= workSatisfactionResult.frontier ? workSatisfactionResult.titleHigh : workSatisfactionResult.titleLow}}</h3>
        <p >{{typeformResponse.workSatisfaction >= workSatisfactionResult.frontier ? workSatisfactionResult.descriptionHigh : workSatisfactionResult.descriptionLow}}</p>
      </div>
    </div>
  </div>

  <div class="footer margin-top margin-bottom column center-children">
    <div class="text-large cooper">Sachez que  grâce à l'accompagnement We Talk, vous pouvez encore gagner <u>en confiance, en sérénité et en énergie.</u></div>
    <app-button class="margin-top" icon="arrow-right" (click)="goToThematics()">Choisissez vos thématiques</app-button>
  </div>
</div>

