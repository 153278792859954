<app-notification *ngIf="typeformNeeded" [buttonMessage]="notificationButtonMessage"
                  (onButtonClick)="goToNeededTypeformForm()" color="#FFA135" icon="heart"
                   [title]="notificationTitle">
  <p *ngFor="let message of notificationContent.split('\n')">{{message}}</p>
</app-notification>

<div class="column gap" [class.margin-top]="typeformNeeded">
  <div *ngFor="let response of responses" class="diagnostic-resume row gap center-children-vertically">
    <app-icon class="mobile-hidden" type="heart-file"/>
    <div class="mobile-column gap diagnostic-resume-part">
      <div class="text-black">{{getTypeformTitle(response.type)}}</div>
      <div class="desktop-hidden center-children">Réalisé le {{response.createdAt.toLocaleDateString()}}</div>
    </div>
    <div class="mobile-hidden grow center-children diagnostic-resume-part">Réalisé le {{response.createdAt.toLocaleDateString()}}</div>
    <div class="grow row gap mobile-hidden center-children diagnostic-resume-part">
      <div class="result row gap">
        <app-icon [style.color]="flourishingColor" type="smile"/>
        <div class="cooper">{{round(response.lifeSatisfaction)}}</div>
      </div>
      <div class="result row gap">
        <app-icon [style.color]="omsMoodColor" type="heart"/>
        <div class="cooper">{{round(response.affectAndRelation)}}</div>
      </div>
      <div class="result row gap">
        <app-icon [style.color]="workSatisfactionColor" type="suit-case"/>
        <div class="cooper">{{round(response.workSatisfaction)}}</div>
      </div>
    </div>
    <div class="pointer row center-children grow-mobile" style="justify-content: flex-end" (click)="goToResult(response)"><u style="white-space: nowrap">Voir le résultat</u> <app-icon style="margin-left: 8px" type="chevron-right" /></div>
  </div>
</div>
